@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-ff-poppins
}

.lineclamp {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.otp-parent input {
  @apply h-[50px] !w-[50px] border-[2px] border-solid rounded border-[#00cada]
}

body::-webkit-scrollbar,
.custom-scroller::-webkit-scrollbar {
  @apply w-[6px] shadow-[inset_0_0_3px_0_#929292]
}

body::-webkit-scrollbar
.custom-scroller::-webkit-scrollbar {
  width: 6px;
  box-shadow: inset 0 0 3px 0 #929292;
  background-color: #ebf6ff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

body::-webkit-scrollbar-thumb,
.custom-scroller-1::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 10px;
  background-color: #929292;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}